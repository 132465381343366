$tablet-width: 768px;
$desktop-width: 1024px;

.template-default {
  font-size: 14px;
  background-color: white;
  color: #282828;
  min-height: 100vh;
  height: 100%;
  width: 100%;

  a, a:visited, a:active { color: black; text-decoration: none; }
  a:hover { color: darkblue; text-decoration: underline; }

  h1 { font-size: 18px; }
  h2 { font-size: 16px; }
  h3 { font-size: 14px; }

  .divs-inline {
    margin: 10px 0 10px 0;

    div {
      display: inline-block;
      margin-right: 10px;
      label {
        margin-right: 5px;
      }
    }
  }

  .profile {
    margin: 70px 5px 5px 5px;
    position: relative;

    .image {
      position: absolute; right: 6px; top: 6px;
      img {
        border-radius: 0.3rem;
      }
    }

    .name-title {
      .name {
        h1 {
          color: black;
          font-size: 16px;
          margin: 4px;
        }
      }

      .title {
        h2 {
          color: #757575;
          font-size: 14px;
          margin: 4px;
        }
      }
    }

    .links {
      padding: 0;

      &-col-1 {
        width: 250px;
        display: inline-block;
      }
      &-col-2 {
        width: 250px;
        display: inline-block;
      }

      .link {
        margin: 0;
        list-style: none;
        font-size: 11px;
        text-wrap: none;
        label {
          font-weight: bold;
          font-size: 14px;
          min-width: 30px;
          display: inline-block;
        }
        a {
          font-weight: normal;
        }

        .mobile { }
        .phone { }
        .email { svg { color: blue; }}
        .blog { margin-top: 10px; }
        .linkedin { }
        .github { }
      }

    }

    .personal {
      display: inline-block;
      width: 250px;
      .address {
        width: 200px;
        label {
          min-width: 30px;
          display: inline-block;
        }
      }
      .birth {
        width: 200px;
        label {
          min-width: 30px;
          display: inline-block;
        }
      }
      .nationality {
        width: 200px;
        svg { color: darkblue; }
        label {
          min-width: 30px;
          display: inline-block;
        }
      }
    }

    .family-hobbies {
      display: block;
      margin: 0 5px 15px 0;
      .family {
        margin-top: 10px;
        display: inline-block;

        div {
          display: inline-block;
          margin-right: 10px;
        }

        .married {
          svg { color: hotpink; }
          label {
            margin-right: 5px;
          }
        }

        .children {
          svg { color: green; }
          label {
            margin-right: 5px;
          }
        }

        .cars {
          svg { color: red; }
          label {
            margin-right: 5px;
          }
        }

        .driver {
          svg {
            color: black;
          }
          label {
            margin-right: 5px;
          }
        }
      }
    }

    .hobbies-container {
      display: inline-block;
      width: 250px;
      .hobbies {
        svg { color: purple; }
        label {
          min-width: 20px;
          display: inline-block;
        }
        div { display: inline-block; }
      }
    }

  }

  .introduction {
    margin: 5px;
    color: black;
    h3 {

    }
    p {
      color: #000347;
      font-size: 12px;
      font-family: Arial;
      text-align: justify;
      padding: 10px 20px 10px 20px;
    }
    p:before {
      color: #000347;
      content: '" ';
      font-size: 20px;
    }
    p:after {
      color: #000347;
      content: ' "';
      font-size: 20px;
    }
  }

  .education {
    margin: 5px;

    .history {

      margin: 5px;
      display: flex;
      flex-wrap: wrap;

      @media (max-width: $tablet-width - 1) { /* phone */
        .history-item { flex: 1 0 98%; }
      }
      @media (min-width: $tablet-width + 1) and (max-width: $desktop-width - 1) { /* tablet */
        .history-item { flex: 1 0 20rem; }
      }
      @media (min-width: $desktop-width) { /* desktop, large */
        .history-item { flex: 1 0 20rem; }
      }

      .history-item {
        background-color: white;
        padding: 5px;
        margin: 1%;
        border-radius: 0.25rem;
        box-shadow: 0 20px 40px -14px rgba(0,0,0,0.25);
        position: relative;
        min-height: 150px;

        .grad {
          svg {
            position: absolute;
            left: 5px;
            top: 5px;
            margin-right: 5px;
            display: inline-block;
          }
        }

        .start {
          display: inline-block;
          margin: 0 10px 0 25px;
        }

        .end {
          display: inline-block;
        }

        .org {
          font-weight: bold;
          display: inline-block;
          margin: 10px 10px 0 0;
        }
        .place {
          margin: 10px 0 0 0;
          display: inline-block;
          svg {
            margin-right: 5px;
            color: #088a0c;
          }
        }

        .type {
          display: block;
          margin: 10px 0 0 0;
          font-family: Courier;
          font-size: 12px;
          color: #85060d;
          font-weight: bold;
        }

        .topic {
          display: block;
          margin: 0;
        }

        .info {
          color: black;
          font-size: 10px;
        }

        .image {
          position: absolute; right: 5px; top: 5px;
        }
      }
    }
  }

  .awards {
    margin: 5px;
    font-size: 12px;

    .history {

      margin: 5px;

      display: flex;
      flex-wrap: wrap;

      @media (max-width: $tablet-width - 1) { /* phone */
        .history-item { flex: 1 0 98%; }
      }
      @media (min-width: $tablet-width + 1) and (max-width: $desktop-width - 1) { /* tablet */
        .history-item { flex: 1 0 20rem; }
      }
      @media (min-width: $desktop-width) { /* desktop, large */
        .history-item { flex: 1 0 20rem; }
      }

      .history-item {
        background-color: white;
        padding: 5px;
        margin: 0 2px 10px 2px;
        border-radius: 0.25rem;
        box-shadow: 0 10px 20px -14px rgba(0,0,0,0.25);
        position: relative;
        min-height: 100px;

        .dates {
          svg {
            margin-right: 5px;
            display: inline-block;
            color: #ece705;
          }

          .date {
            display: inline-block;
            margin-right: 10px;
          }

        }

        .org-place {
          margin: 3px 0 3px 0;
          .org {
            font-weight: bold;
            display: inline-block;
            margin-right: 10px;
          }
          .place {
            display: inline-block;
            svg {
              margin-right: 5px;
              color: #088a0c;
            }
          }
        }

        .info {
          color: black;
        }
      }
    }
  }

  .training {
    margin: 5px;
    font-size: 12px;

    .history {

      margin: 5px;

      display: flex;
      flex-wrap: wrap;

      @media (max-width: $tablet-width - 1) { /* phone */
        .history-item { flex: 1 0 98%; }
      }
      @media (min-width: $tablet-width + 1) and (max-width: $desktop-width - 1) { /* tablet */
        .history-item { flex: 1 0 20rem; }
      }
      @media (min-width: $desktop-width) { /* desktop, large */
        .history-item { flex: 1 0 20rem; }
      }

      .history-item {
        background-color: white;
        padding: 5px;
        margin: 0 2px 10px 2px;
        border-radius: 0.25rem;
        box-shadow: 0 10px 20px -14px rgba(0,0,0,0.25);
        position: relative;
        min-height: 100px;

        .dates {
          svg {
            margin-right: 5px;
            display: inline-block;
            color: #3f45ec;
          }

          .date {
            display: inline-block;
            margin-right: 10px;
          }

        }

        .org-place {
          margin: 3px 0 3px 0;
          .org {
            font-weight: bold;
            display: inline-block;
            margin-right: 10px;
          }
          .place {
            display: inline-block;
            svg {
              margin-right: 5px;
              color: #088a0c;
            }
          }
        }

        .topic {
          color: black;
        }
      }
    }
  }

  .experience {
    margin: 5px;

    h4 {
      font-size: 16px;
    }
    .history {

      margin: 5px;

      .history-item {
        background-color: #edecfa;
        color: #323134;
        padding: 3px;
        border-radius: 0.25rem;
        box-shadow: 0 20px 40px -14px rgba(0,0,0,0.25);
        margin: 5px 5px 15px 5px;
        position: relative;

        .dates {
          max-width: 70%;
          .start {
            display: inline-block;
            margin-right: 8px;
          }
          .end {
            display: inline-block;
            margin-right: 10px;
          }
        }
        .periods {
          max-width: 70%;
        }

        .org-place {
          .org {
            font-weight: bold;
            display: inline-block;
            margin: 5px 10px 5px 0;
            color: #a80e15;
          }
          .place {
            display: inline-block;
            svg {
              margin-right: 5px;
              color: #088a0c;
            }
          }
        }

        .title {
          display: block;
          margin: 0;
          font-weight: bold;
          color: #310631;
          text-align: right;
          position: absolute; right: 6px; top: 6px;
        }

        .type {
          display: inline-block;
          margin: 0;
          font-family: Courier;
          font-size: 12px;
          font-weight: bold;
          color: #1a1b96;
          position: absolute; right: 6px; top: 26px;
        }

        .tech {
          display: block;
          margin-top: 6px;
          label {
            font-weight: bold;
            margin-right: 5px;
          }
          span {
            font-size: 11px;
            font-family: "Comic Sans MS";
          }
        }

        .tags {
          display: block;
          margin-top: 6px;
          label {
            font-weight: bold;
            margin-right: 5px;
          }
          span {
            border-radius: 0.15rem;
            background-color: #faf5cf;
            color: black;
            margin: 2px;
            padding: 2px 4px 2px 4px;
            font-size: 10px;
            font-family: Courier;
          }
        }

        .info-projects {
          margin: 10px 5px 10px 5px;
          padding: 3px;
          background-color: #f7f7f7;
          color: black;
          border-radius: 0.15rem;

          p.info {
            margin: 3px;
            text-align: justify;
            font-family: "Times New Roman";
          }
          .projects {
            h4 {
              margin: 6px;
            }
            .project {
              margin-bottom: 10px;
              h5 {
                margin: 4px;
                font-weight: normal;
              }
              .info {
                font-size: 13px;
                margin-left: 6px;
              }
              .tech {
                font-size: 12px;
                margin-left: 6px;
                span {
                  font-size: 11px;
                  font-family: "Comic Sans MS";
                }
              }
              .place {
                margin-left: 6px;
                display: inline-block;
                font-size: 11px;
                svg {
                  margin-right: 5px;
                  color: #088a0c;
                }
              }
            }
          }
        }
      }
    }
  }

}

